@tailwind base;

@layer base {
  body {
    text-rendering: geometricPrecision;
    -webkit-font-smoothing: antialiased;
    // @media only screen and (max-width: 600px) {
    //   font-size: 14px!important;
    // }
    // @media only screen and (max-width: 400px) {
    //   font-size: 12px!important;
    // }
    //height: 100vh;
  }
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    @apply bg-gray-100;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    @apply bg-gray-400 rounded-md;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    @apply bg-gray-500;
  }
  button {
    span {
      text-transform: uppercase !important;
    }
  }

  h1 {
    @apply text-2xl font-bold;
  }

  h2 {
    @apply text-xl font-bold;
  }

  h3 {
    @apply text-lg font-bold;
  }
  h4 {
    @apply font-bold;
  }
  h5 {
    @apply text-sm font-bold;
  }
  label {
    @apply first-letter:uppercase;
  }
  p {
    @apply text-sm;
  }
  small {
    @apply text-xs;
  }

  .grid-cols-16 {
    grid-template-columns: repeat(16, minmax(0, 1fr));
  }
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field
  .mdc-notched-outline__notch {
  border-right-style: hidden;
}

.ql-editor {
  padding: 4px 8px;
}
.mat-mdc-dialog-container {
  --mdc-dialog-supporting-text-color: black;
}
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

a.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
}

quill-editor {
  @apply h-40 w-full max-w-full;
}

.FIE_topbar {
  display: none !important;
}
