html {
  // --mat-expansion-container-background-color : white;
  // --mdc-outlined-card-container-color:white;
  // --mat-table-background-color:white;
  // --mat-paginator-container-background-color:white;
  // --mat-sidenav-container-background-color:white;
  // --mat-sidenav-content-background-color:white;
  // --mat-sidenav-container-shape:0px;
  // --mat-paginator-container-text-size:1rem;
  // --mat-toolbar-container-background-color:white;
  // --mat-divider-color:#e1e0f9;
  // .mat-grid-tile-content {
  //   justify-content: start;

  // }
  // .mat-mdc-header-cell {
  //   @apply capitalize;
  // }
  // .mat-column-action {
  //   max-width: 8rem;
  // }

  --mat-divider-color: var(--mat-sys-outline-variant);
  --mat-table-row-item-outline-color: var(--mat-sys-outline-variant);
  --mat-sidenav-content-background-color: white;
  --mat-sidenav-container-shape: 0px;
  --mat-sys-surface: #faf9fd; //
  --mat-sys-background: #f8f1f6;

  // h1 {
  //   font: var(--mat-sys-display-large);
  // }

  // h2 {
  //   font: var(--mat-sys-display-medium);
  // }

  // h3 {
  //   font: var(--mat-sys-display-small);
  // }

  // h4 {
  //   font: var(--mat-sys-headline-large);
  // }

  // h5 {
  //   font: var(--mat-sys-headline-medium);
  // }

  // h6 {
  //   font: var(--mat-sys-headline-small);
  // }
}
