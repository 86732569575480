.image--pulsing {
  animation: pulse 1s infinite ease-in-out;
  min-height: 150px;
}

.text--pulsing {
  animation: pulse 1s infinite ease-in-out;
  height: 10px;
  color: transparent !important;
}

@keyframes pulse {
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }
  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }
  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
}
@tailwind base;
@layer base {
  body {
    text-rendering: geometricPrecision;
    -webkit-font-smoothing: antialiased;
    height: 100vh;
  }
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    @apply bg-gray-100;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    @apply bg-gray-400 rounded-md;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    @apply bg-gray-500;
  }
  button span {
    text-transform: uppercase !important;
  }
  h1 {
    @apply text-2xl font-bold;
  }
  h2 {
    @apply text-xl font-bold;
  }
  h3 {
    @apply text-lg font-bold;
  }
  h4 {
    @apply font-bold;
  }
  h5 {
    @apply text-sm font-bold;
  }
  label {
    @apply first-letter:uppercase;
  }
  p {
    @apply text-sm;
  }
  small {
    @apply text-xs;
  }
  .grid-cols-16 {
    grid-template-columns: repeat(16, minmax(0, 1fr));
  }
}
.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-right-style: hidden;
}

.ql-editor {
  padding: 4px 8px;
}

.mat-mdc-dialog-container {
  --mdc-dialog-supporting-text-color: black ;
}

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

a.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
}

quill-editor {
  @apply h-40 w-full max-w-full;
}

.FIE_topbar {
  display: none !important;
}

@tailwind components;
@layer components {
  .tag-box-option {
    @apply flex items-center border border-gray-200 text-gray-700 rounded-md text-sm font-medium py-0.5 px-2 hover:cursor-pointer;
  }
  .tag-box {
    @apply tag-box-option hover:cursor-auto bg-gray-200 text-black;
  }
  .tag-box__selected {
    @apply bg-green-200 border-green-200 text-black;
  }
  .anchor[href] {
    @apply underline text-blue-600 hover:text-blue-800;
  }
  .item--boxed {
    @apply rounded-lg bg-white p-4 shadow-sm;
  }
  .section-header {
    @apply text-gray-700 sticky p-6 bg-slate-100 z-30 top-0;
  }
  .section-header h2 {
    @apply text-gray-700 flex items-center first-letter:uppercase;
  }
  .section-header h2 span {
    @apply first-letter:uppercase;
  }
  .section-header .btns {
    @apply flex justify-end;
  }
  label.required::after {
    content: " *";
  }
  .entity-key-value-container__editable {
    @apply transition-all duration-100 ease-in;
  }
  .entity-key-value-container__editable .action-btn {
    @apply mt-3 text-gray-600 invisible;
  }
  .entity-key-value-container__editable:hover {
    @apply drop-shadow-lg;
  }
  .entity-key-value-container__editable:hover .action-btn {
    @apply visible;
  }
  .entity-key-value-container {
    @apply px-4 pb-4 border border-solid border-gray-300 rounded-lg self-start bg-white;
  }
  .entity-key-value-container .main-title {
    @apply first-letter:uppercase text-lg;
  }
  .entity-key-value-container .sub-title {
    @apply first-letter:uppercase font-medium text-gray-700;
  }
  .entity-key-value-container .key-value-record {
    @apply flex flex-col;
  }
  .entity-key-value-container .key-value-record .key {
    @apply first-letter:uppercase text-gray-500;
  }
}
@tailwind utilities;
@layer utilities {
  .tag {
    @apply bg-slate-300 px-2 py-1 rounded-full;
  }
  .clickable {
    @apply opacity-80 hover:opacity-100 hover:cursor-pointer transition-all;
  }
  .fieldset {
    @apply gap-4 flex sm:flex-row flex-col p-6 border w-full border-gray-200 rounded-md;
  }
  .fieldset .field-label {
    @apply first-letter:uppercase font-medium text-gray-800 mb-4;
  }
  .fieldset aside {
    @apply sm:w-1/3 w-full;
  }
  .fieldset main {
    @apply sm:w-2/3 w-full;
  }
  .v-ellipsis-1 .ql-editor {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .mention-list-container {
    background-color: #fff !important;
    border: 1px solid #f0f0f0 !important;
    border-radius: 4px !important;
    box-shadow: 0 2px 12px 0 rgba(30, 30, 30, 0.08) !important;
    overflow: auto !important;
    width: min(300px, 100vw);
    z-index: 9001 !important;
  }
}
.side-entry {
  display: block;
  background: #fff;
  animation: dialog-enter 0.3s ease;
  height: 100%;
  overflow-y: auto;
}

@keyframes dialog-enter {
  from {
    transform: translateX(100%);
  }
  to {
    transform: none;
  }
}
/* stylelint-disable-next-line scss/no-global-function-names */
.mat-button-loading .mdc-button__label,
.mat-button-loading .mat-icon {
  visibility: hidden;
}
.mat-button-loading .mat-mdc-progress-spinner {
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
}

.mat-column-resize-table.cdk-column-resize-with-resized-column {
  table-layout: fixed;
}

.mat-column-resize-flex .mat-mdc-header-cell,
.mat-column-resize-flex .mat-mdc-cell {
  box-sizing: border-box;
  min-width: 32px;
}

.mat-mdc-header-cell {
  position: relative;
}

.mat-resizable {
  box-sizing: border-box;
}

.mat-mdc-header-cell:not(.mat-resizable)::after,
.mat-resizable-handle {
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  width: 1px;
  background: transparent;
  transition: background 300ms cubic-bezier(0.55, 0, 0.55, 0.2);
}

.mat-mdc-header-cell:not(.mat-resizable)::after {
  content: "";
}

[dir=rtl] .mat-mdc-header-cell:not(.mat-resizable)::after,
[dir=rtl] .mat-resizable-handle {
  left: 0;
  right: auto;
}
[dir=rtl] .mat-mdc-header-row.cdk-column-resize-hover-or-active .mat-header-cell,
[dir=rtl] .mat-mdc-header-row.cdk-column-resize-hover-or-active .mat-mdc-header-cell {
  border-left: none;
}

.mat-mdc-header-row.cdk-column-resize-hover-or-active .mat-mdc-header-cell {
  border-right: none;
}
.mat-mdc-header-row.cdk-column-resize-hover-or-active .mat-mdc-header-cell:not(.mat-resizable)::after {
  background-color: var(--mtx-grid-resizable-handle-disabled-background-color, var(--mat-app-outline-variant));
}
.mat-mdc-header-row.cdk-column-resize-hover-or-active .mat-resizable-handle {
  background-color: var(--mtx-grid-resizable-handle-hover-background-color, var(--mat-app-primary));
}

.mat-resizable.cdk-resizable-overlay-thumb-active > .mat-resizable-handle {
  opacity: 0;
  transition: none;
}

.mat-resizable-handle:focus,
.mat-mdc-header-row.cdk-column-resize-hover-or-active .mat-resizable-handle:focus {
  outline: none;
  background-color: var(--mtx-grid-resizable-handle-active-background-color, var(--mat-app-primary));
}

.mat-column-resize-overlay-thumb {
  background: transparent;
  cursor: col-resize;
  width: 100%;
  height: 100%;
  transition: background 300ms cubic-bezier(0.55, 0, 0.55, 0.2);
  -webkit-user-select: none;
  user-select: none;
}
.mat-column-resize-overlay-thumb:active {
  will-change: transform;
  background: linear-gradient(90deg, transparent, transparent 7px, var(--mtx-grid-resizable-handle-active-background-color, var(--mat-app-primary)) 7px, var(--mtx-grid-resizable-handle-active-background-color, var(--mat-app-primary)) 9px, transparent 9px, transparent);
}
.mat-column-resize-overlay-thumb:active .mat-column-resize-overlay-thumb-top {
  background: linear-gradient(90deg, transparent, transparent 4px, var(--mtx-grid-resizable-handle-active-background-color, var(--mat-app-primary)) 4px, var(--mtx-grid-resizable-handle-active-background-color, var(--mat-app-primary)) 12px, transparent 12px, transparent);
}

.mat-column-resize-overlay-thumb-top {
  width: 100%;
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: var(--mat-app-elevation-shadow-level-0, none);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: var(--mat-app-elevation-shadow-level-1, none);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: var(--mat-app-elevation-shadow-level-2, none);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: var(--mat-app-elevation-shadow-level-3, none);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: var(--mat-app-elevation-shadow-level-4, none);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: var(--mat-app-elevation-shadow-level-5, none);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: var(--mat-app-elevation-shadow-level-6, none);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: var(--mat-app-elevation-shadow-level-7, none);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: var(--mat-app-elevation-shadow-level-8, none);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: var(--mat-app-elevation-shadow-level-9, none);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: var(--mat-app-elevation-shadow-level-10, none);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: var(--mat-app-elevation-shadow-level-11, none);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: var(--mat-app-elevation-shadow-level-12, none);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: var(--mat-app-elevation-shadow-level-13, none);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: var(--mat-app-elevation-shadow-level-14, none);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: var(--mat-app-elevation-shadow-level-15, none);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: var(--mat-app-elevation-shadow-level-16, none);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: var(--mat-app-elevation-shadow-level-17, none);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: var(--mat-app-elevation-shadow-level-18, none);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: var(--mat-app-elevation-shadow-level-19, none);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: var(--mat-app-elevation-shadow-level-20, none);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: var(--mat-app-elevation-shadow-level-21, none);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: var(--mat-app-elevation-shadow-level-22, none);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: var(--mat-app-elevation-shadow-level-23, none);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: var(--mat-app-elevation-shadow-level-24, none);
}

html {
  --mat-sys-on-surface: initial;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, var(--mat-sys-background, transparent));
  color: var(--mat-app-text-color, var(--mat-sys-on-background, inherit));
}

html {
  --mat-app-background-color: #fcf8fd;
  --mat-app-text-color: #1b1b1f;
  --mat-app-elevation-shadow-level-0: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-3: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-4: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-5: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-6: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-7: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-8: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-9: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-10: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-11: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-12: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-13: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-14: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-15: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-16: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-17: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-18: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-19: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-20: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-21: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-22: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-23: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-24: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
html {
  --mat-ripple-color: rgba(27, 27, 31, 0.1);
}
html {
  --mat-option-selected-state-label-text-color: #191a2c;
  --mat-option-label-text-color: #1b1b1f;
  --mat-option-hover-state-layer-color: rgba(27, 27, 31, 0.08);
  --mat-option-focus-state-layer-color: rgba(27, 27, 31, 0.12);
  --mat-option-selected-state-layer-color: #e1e0f9;
  --mat-option-label-text-font: Roboto, sans-serif;
  --mat-option-label-text-line-height: 1.25rem;
  --mat-option-label-text-size: 1rem;
  --mat-option-label-text-tracking: 0.006rem;
  --mat-option-label-text-weight: 400;
}
html {
  --mat-optgroup-label-text-color: #46464f;
  --mat-optgroup-label-text-font: Roboto, sans-serif;
  --mat-optgroup-label-text-line-height: 1.25rem;
  --mat-optgroup-label-text-size: 0.875rem;
  --mat-optgroup-label-text-tracking: 0.006rem;
  --mat-optgroup-label-text-weight: 500;
}
html {
  --mat-full-pseudo-checkbox-selected-icon-color: #343dff;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #ffffff;
  --mat-full-pseudo-checkbox-unselected-icon-color: #46464f;
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fcf8fd;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: rgba(27, 27, 31, 0.38);
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: rgba(27, 27, 31, 0.38);
}
html {
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #343dff;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: rgba(27, 27, 31, 0.38);
}
html {
  --mdc-elevated-card-container-color: #f6f2f7;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-shape: 12px;
}
html {
  --mdc-outlined-card-container-color: #fcf8fd;
  --mdc-outlined-card-outline-color: #c7c5d0;
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-shape: 12px;
  --mdc-outlined-card-outline-width: 1px;
}
html {
  --mat-card-subtitle-text-color: #1b1b1f;
  --mat-card-title-text-font: Roboto;
  --mat-card-title-text-line-height: 1.75rem;
  --mat-card-title-text-size: 1.375rem;
  --mat-card-title-text-tracking: 0;
  --mat-card-title-text-weight: 400;
  --mat-card-subtitle-text-font: Roboto, sans-serif;
  --mat-card-subtitle-text-line-height: 1.5rem;
  --mat-card-subtitle-text-size: 1rem;
  --mat-card-subtitle-text-tracking: 0.009rem;
  --mat-card-subtitle-text-weight: 500;
}
html {
  --mdc-linear-progress-active-indicator-color: #343dff;
  --mdc-linear-progress-track-color: #e4e1ec;
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
}
html {
  --mdc-plain-tooltip-container-color: #303034;
  --mdc-plain-tooltip-supporting-text-color: #f3eff4;
  --mdc-plain-tooltip-supporting-text-line-height: 1rem;
  --mdc-plain-tooltip-supporting-text-font: Roboto, sans-serif;
  --mdc-plain-tooltip-supporting-text-size: 0.75rem;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: 0.025rem;
  --mdc-plain-tooltip-container-shape: 4px;
}
html {
  --mdc-filled-text-field-caret-color: #343dff;
  --mdc-filled-text-field-focus-active-indicator-color: #343dff;
  --mdc-filled-text-field-focus-label-text-color: #343dff;
  --mdc-filled-text-field-container-color: #e4e1ec;
  --mdc-filled-text-field-disabled-container-color: rgba(27, 27, 31, 0.04);
  --mdc-filled-text-field-label-text-color: #46464f;
  --mdc-filled-text-field-hover-label-text-color: #46464f;
  --mdc-filled-text-field-disabled-label-text-color: rgba(27, 27, 31, 0.38);
  --mdc-filled-text-field-input-text-color: #1b1b1f;
  --mdc-filled-text-field-disabled-input-text-color: rgba(27, 27, 31, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: #46464f;
  --mdc-filled-text-field-error-hover-label-text-color: #410002;
  --mdc-filled-text-field-error-focus-label-text-color: #ba1a1a;
  --mdc-filled-text-field-error-label-text-color: #ba1a1a;
  --mdc-filled-text-field-active-indicator-color: #46464f;
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(27, 27, 31, 0.38);
  --mdc-filled-text-field-hover-active-indicator-color: #1b1b1f;
  --mdc-filled-text-field-error-active-indicator-color: #ba1a1a;
  --mdc-filled-text-field-error-focus-active-indicator-color: #ba1a1a;
  --mdc-filled-text-field-error-hover-active-indicator-color: #410002;
  --mdc-filled-text-field-label-text-font: Roboto, sans-serif;
  --mdc-filled-text-field-label-text-size: 1rem;
  --mdc-filled-text-field-label-text-tracking: 0.031rem;
  --mdc-filled-text-field-label-text-weight: 400;
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
}
html {
  --mdc-outlined-text-field-caret-color: #343dff;
  --mdc-outlined-text-field-focus-outline-color: #343dff;
  --mdc-outlined-text-field-focus-label-text-color: #343dff;
  --mdc-outlined-text-field-label-text-color: #46464f;
  --mdc-outlined-text-field-hover-label-text-color: #1b1b1f;
  --mdc-outlined-text-field-disabled-label-text-color: rgba(27, 27, 31, 0.38);
  --mdc-outlined-text-field-input-text-color: #1b1b1f;
  --mdc-outlined-text-field-disabled-input-text-color: rgba(27, 27, 31, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: #46464f;
  --mdc-outlined-text-field-error-focus-label-text-color: #ba1a1a;
  --mdc-outlined-text-field-error-label-text-color: #ba1a1a;
  --mdc-outlined-text-field-error-hover-label-text-color: #410002;
  --mdc-outlined-text-field-outline-color: #777680;
  --mdc-outlined-text-field-disabled-outline-color: rgba(27, 27, 31, 0.12);
  --mdc-outlined-text-field-hover-outline-color: #1b1b1f;
  --mdc-outlined-text-field-error-focus-outline-color: #ba1a1a;
  --mdc-outlined-text-field-error-hover-outline-color: #410002;
  --mdc-outlined-text-field-error-outline-color: #ba1a1a;
  --mdc-outlined-text-field-label-text-font: Roboto, sans-serif;
  --mdc-outlined-text-field-label-text-size: 1rem;
  --mdc-outlined-text-field-label-text-tracking: 0.031rem;
  --mdc-outlined-text-field-label-text-weight: 400;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
}
html {
  --mat-form-field-focus-select-arrow-color: #343dff;
  --mat-form-field-disabled-input-text-placeholder-color: rgba(27, 27, 31, 0.38);
  --mat-form-field-state-layer-color: #1b1b1f;
  --mat-form-field-error-text-color: #ba1a1a;
  --mat-form-field-select-option-text-color: #1b1b1f;
  --mat-form-field-select-disabled-option-text-color: rgba(27, 27, 31, 0.38);
  --mat-form-field-leading-icon-color: #46464f;
  --mat-form-field-disabled-leading-icon-color: rgba(27, 27, 31, 0.38);
  --mat-form-field-trailing-icon-color: #46464f;
  --mat-form-field-disabled-trailing-icon-color: rgba(27, 27, 31, 0.38);
  --mat-form-field-error-focus-trailing-icon-color: #ba1a1a;
  --mat-form-field-error-hover-trailing-icon-color: #410002;
  --mat-form-field-error-trailing-icon-color: #ba1a1a;
  --mat-form-field-enabled-select-arrow-color: #46464f;
  --mat-form-field-disabled-select-arrow-color: rgba(27, 27, 31, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.08;
  --mat-form-field-container-text-font: Roboto, sans-serif;
  --mat-form-field-container-text-line-height: 1.5rem;
  --mat-form-field-container-text-size: 1rem;
  --mat-form-field-container-text-tracking: 0.031rem;
  --mat-form-field-container-text-weight: 400;
  --mat-form-field-subscript-text-font: Roboto, sans-serif;
  --mat-form-field-subscript-text-line-height: 1rem;
  --mat-form-field-subscript-text-size: 0.75rem;
  --mat-form-field-subscript-text-tracking: 0.025rem;
  --mat-form-field-subscript-text-weight: 400;
  --mat-form-field-container-height: 56px;
  --mat-form-field-filled-label-display: block;
  --mat-form-field-container-vertical-padding: 16px;
  --mat-form-field-filled-with-label-container-padding-top: 24px;
  --mat-form-field-filled-with-label-container-padding-bottom: 8px;
  --mat-form-field-focus-state-layer-opacity: 0;
}
html {
  --mat-select-panel-background-color: #f0edf1;
  --mat-select-enabled-trigger-text-color: #1b1b1f;
  --mat-select-disabled-trigger-text-color: rgba(27, 27, 31, 0.38);
  --mat-select-placeholder-text-color: #46464f;
  --mat-select-enabled-arrow-color: #46464f;
  --mat-select-disabled-arrow-color: rgba(27, 27, 31, 0.38);
  --mat-select-focused-arrow-color: #343dff;
  --mat-select-invalid-arrow-color: #ba1a1a;
  --mat-select-trigger-text-font: Roboto, sans-serif;
  --mat-select-trigger-text-line-height: 1.5rem;
  --mat-select-trigger-text-size: 1rem;
  --mat-select-trigger-text-tracking: 0.031rem;
  --mat-select-trigger-text-weight: 400;
  --mat-select-arrow-transform: translateY(-8px);
  --mat-select-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
html {
  --mat-autocomplete-background-color: #f0edf1;
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
html {
  --mdc-dialog-container-color: #fcf8fd;
  --mdc-dialog-subhead-color: #1b1b1f;
  --mdc-dialog-supporting-text-color: #46464f;
  --mdc-dialog-subhead-font: Roboto;
  --mdc-dialog-subhead-line-height: 2rem;
  --mdc-dialog-subhead-size: 1.5rem;
  --mdc-dialog-subhead-weight: 400;
  --mdc-dialog-subhead-tracking: 0;
  --mdc-dialog-supporting-text-font: Roboto, sans-serif;
  --mdc-dialog-supporting-text-line-height: 1.25rem;
  --mdc-dialog-supporting-text-size: 0.875rem;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: 0.016rem;
  --mdc-dialog-container-shape: 28px;
}
html {
  --mat-dialog-container-elevation-shadow: none;
  --mat-dialog-container-max-width: 560px;
  --mat-dialog-container-small-max-width: calc(100vw - 32px);
  --mat-dialog-container-min-width: 280px;
  --mat-dialog-actions-alignment: flex-end;
  --mat-dialog-actions-padding: 16px 24px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px 0;
  --mat-dialog-headline-padding: 6px 24px 13px;
}
html {
  --mdc-chip-outline-color: #777680;
  --mdc-chip-disabled-outline-color: rgba(27, 27, 31, 0.12);
  --mdc-chip-focus-outline-color: #46464f;
  --mdc-chip-hover-state-layer-opacity: 0.08;
  --mdc-chip-selected-hover-state-layer-opacity: 0.08;
  --mdc-chip-disabled-label-text-color: rgba(27, 27, 31, 0.38);
  --mdc-chip-elevated-selected-container-color: #e1e0f9;
  --mdc-chip-flat-disabled-selected-container-color: rgba(27, 27, 31, 0.12);
  --mdc-chip-focus-state-layer-color: #46464f;
  --mdc-chip-hover-state-layer-color: #46464f;
  --mdc-chip-selected-hover-state-layer-color: #191a2c;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: #191a2c;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #46464f;
  --mdc-chip-selected-label-text-color: #191a2c;
  --mdc-chip-with-icon-icon-color: #46464f;
  --mdc-chip-with-icon-disabled-icon-color: #1b1b1f;
  --mdc-chip-with-icon-selected-icon-color: #191a2c;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #1b1b1f;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #46464f;
  --mdc-chip-label-text-font: Roboto, sans-serif;
  --mdc-chip-label-text-line-height: 1.25rem;
  --mdc-chip-label-text-size: 0.875rem;
  --mdc-chip-label-text-tracking: 0.006rem;
  --mdc-chip-label-text-weight: 500;
  --mdc-chip-container-height: 32px;
  --mdc-chip-container-shape-radius: 8px;
  --mdc-chip-with-avatar-avatar-shape-radius: 24px;
  --mdc-chip-with-avatar-avatar-size: 24px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 1px;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 0.38;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 0.38;
  --mdc-chip-with-icon-disabled-icon-opacity: 0.38;
  --mdc-chip-elevated-container-color: transparent;
}
html {
  --mat-chip-trailing-action-state-layer-color: #46464f;
  --mat-chip-selected-trailing-action-state-layer-color: #191a2c;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0.08;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0.12;
  --mat-chip-selected-disabled-trailing-icon-color: #1b1b1f;
  --mat-chip-selected-trailing-icon-color: #191a2c;
  --mat-chip-disabled-container-opacity: 1;
  --mat-chip-trailing-action-opacity: 1;
  --mat-chip-trailing-action-focus-opacity: 1;
}
html {
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.08;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.12;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.08;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.12;
  --mdc-switch-selected-focus-state-layer-color: #343dff;
  --mdc-switch-selected-handle-color: #ffffff;
  --mdc-switch-selected-hover-state-layer-color: #343dff;
  --mdc-switch-selected-pressed-state-layer-color: #343dff;
  --mdc-switch-selected-focus-handle-color: #e0e0ff;
  --mdc-switch-selected-hover-handle-color: #e0e0ff;
  --mdc-switch-selected-pressed-handle-color: #e0e0ff;
  --mdc-switch-selected-focus-track-color: #343dff;
  --mdc-switch-selected-hover-track-color: #343dff;
  --mdc-switch-selected-pressed-track-color: #343dff;
  --mdc-switch-selected-track-color: #343dff;
  --mdc-switch-disabled-selected-handle-color: #fcf8fd;
  --mdc-switch-disabled-selected-icon-color: #1b1b1f;
  --mdc-switch-disabled-selected-track-color: #1b1b1f;
  --mdc-switch-disabled-unselected-handle-color: #1b1b1f;
  --mdc-switch-disabled-unselected-icon-color: #e4e1ec;
  --mdc-switch-disabled-unselected-track-color: #e4e1ec;
  --mdc-switch-selected-icon-color: #00006e;
  --mdc-switch-unselected-focus-handle-color: #46464f;
  --mdc-switch-unselected-focus-state-layer-color: #1b1b1f;
  --mdc-switch-unselected-focus-track-color: #e4e1ec;
  --mdc-switch-unselected-handle-color: #777680;
  --mdc-switch-unselected-hover-handle-color: #46464f;
  --mdc-switch-unselected-hover-state-layer-color: #1b1b1f;
  --mdc-switch-unselected-hover-track-color: #e4e1ec;
  --mdc-switch-unselected-icon-color: #e4e1ec;
  --mdc-switch-unselected-pressed-handle-color: #46464f;
  --mdc-switch-unselected-pressed-state-layer-color: #1b1b1f;
  --mdc-switch-unselected-pressed-track-color: #e4e1ec;
  --mdc-switch-unselected-track-color: #e4e1ec;
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-shape: 9999px;
  --mdc-switch-selected-icon-size: 16px;
  --mdc-switch-track-height: 32px;
  --mdc-switch-track-shape: 9999px;
  --mdc-switch-track-width: 52px;
  --mdc-switch-unselected-icon-size: 16px;
  --mdc-switch-state-layer-size: 40px;
}
html {
  --mat-switch-track-outline-color: #777680;
  --mat-switch-disabled-unselected-track-outline-color: #1b1b1f;
  --mat-switch-label-text-color: #1b1b1f;
  --mat-switch-label-text-font: Roboto, sans-serif;
  --mat-switch-label-text-line-height: 1.25rem;
  --mat-switch-label-text-size: 0.875rem;
  --mat-switch-label-text-tracking: 0.016rem;
  --mat-switch-label-text-weight: 400;
  --mat-switch-disabled-selected-handle-opacity: 1;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 16px;
  --mat-switch-selected-handle-size: 24px;
  --mat-switch-pressed-handle-size: 28px;
  --mat-switch-with-icon-handle-size: 24px;
  --mat-switch-selected-handle-horizontal-margin: 0 24px;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0 24px;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0 22px;
  --mat-switch-unselected-handle-horizontal-margin: 0 8px;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0 4px;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0 2px;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 0;
  --mat-switch-visible-track-transition: opacity 75ms;
  --mat-switch-hidden-track-transition: opacity 75ms;
  --mat-switch-track-outline-width: 2px;
  --mat-switch-selected-track-outline-width: 2px;
  --mat-switch-selected-track-outline-color: transparent;
  --mat-switch-disabled-unselected-track-outline-width: 2px;
}
html {
  --mdc-radio-disabled-selected-icon-color: #1b1b1f;
  --mdc-radio-disabled-unselected-icon-color: #1b1b1f;
  --mdc-radio-unselected-hover-icon-color: #1b1b1f;
  --mdc-radio-unselected-focus-icon-color: #1b1b1f;
  --mdc-radio-unselected-icon-color: #46464f;
  --mdc-radio-unselected-pressed-icon-color: #1b1b1f;
  --mdc-radio-selected-focus-icon-color: #343dff;
  --mdc-radio-selected-hover-icon-color: #343dff;
  --mdc-radio-selected-icon-color: #343dff;
  --mdc-radio-selected-pressed-icon-color: #343dff;
  --mdc-radio-state-layer-size: 40px;
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
}
html {
  --mat-radio-ripple-color: #1b1b1f;
  --mat-radio-checked-ripple-color: #343dff;
  --mat-radio-disabled-label-color: rgba(27, 27, 31, 0.38);
  --mat-radio-label-text-color: #1b1b1f;
  --mat-radio-label-text-font: Roboto, sans-serif;
  --mat-radio-label-text-line-height: 1.25rem;
  --mat-radio-label-text-size: 0.875rem;
  --mat-radio-label-text-tracking: 0.016rem;
  --mat-radio-label-text-weight: 400;
  --mat-radio-touch-target-display: block;
}
html {
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-slider-handle-color: #343dff;
  --mdc-slider-focus-handle-color: #343dff;
  --mdc-slider-hover-handle-color: #343dff;
  --mdc-slider-active-track-color: #343dff;
  --mdc-slider-inactive-track-color: #e4e1ec;
  --mdc-slider-with-tick-marks-inactive-container-color: #46464f;
  --mdc-slider-with-tick-marks-active-container-color: #ffffff;
  --mdc-slider-disabled-active-track-color: #1b1b1f;
  --mdc-slider-disabled-handle-color: #1b1b1f;
  --mdc-slider-disabled-inactive-track-color: #1b1b1f;
  --mdc-slider-label-container-color: #343dff;
  --mdc-slider-label-label-text-color: #ffffff;
  --mdc-slider-with-overlap-handle-outline-color: #ffffff;
  --mdc-slider-with-tick-marks-disabled-container-color: #1b1b1f;
  --mdc-slider-label-label-text-font: Roboto, sans-serif;
  --mdc-slider-label-label-text-size: 0.75rem;
  --mdc-slider-label-label-text-line-height: 1rem;
  --mdc-slider-label-label-text-tracking: 0.031rem;
  --mdc-slider-label-label-text-weight: 500;
  --mdc-slider-active-track-height: 4px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 9999px;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.38;
  --mdc-slider-with-tick-marks-container-shape: 9999px;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.38;
}
html {
  --mat-slider-ripple-color: #343dff;
  --mat-slider-hover-state-layer-color: rgba(52, 61, 255, 0.05);
  --mat-slider-focus-state-layer-color: rgba(52, 61, 255, 0.2);
  --mat-slider-value-indicator-width: 28px;
  --mat-slider-value-indicator-height: 28px;
  --mat-slider-value-indicator-caret-display: none;
  --mat-slider-value-indicator-border-radius: 50% 50% 50% 0;
  --mat-slider-value-indicator-padding: 0;
  --mat-slider-value-indicator-text-transform: rotate(45deg);
  --mat-slider-value-indicator-container-transform: translateX(-50%) rotate(-45deg);
  --mat-slider-value-indicator-opacity: 1;
}
html {
  --mat-menu-item-label-text-color: #1b1b1f;
  --mat-menu-item-icon-color: #46464f;
  --mat-menu-item-hover-state-layer-color: rgba(27, 27, 31, 0.08);
  --mat-menu-item-focus-state-layer-color: rgba(27, 27, 31, 0.12);
  --mat-menu-container-color: #f0edf1;
  --mat-menu-divider-color: #e4e1ec;
  --mat-menu-item-label-text-font: Roboto, sans-serif;
  --mat-menu-item-label-text-size: 0.875rem;
  --mat-menu-item-label-text-tracking: 0.006rem;
  --mat-menu-item-label-text-line-height: 1.25rem;
  --mat-menu-item-label-text-weight: 500;
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 8px;
  --mat-menu-divider-top-spacing: 8px;
  --mat-menu-item-spacing: 12px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 12px;
  --mat-menu-item-trailing-spacing: 12px;
  --mat-menu-item-with-icon-leading-spacing: 12px;
  --mat-menu-item-with-icon-trailing-spacing: 12px;
  --mat-menu-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
html {
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: #e0e0ff;
  --mdc-list-list-item-disabled-state-layer-color: #1b1b1f;
  --mdc-list-list-item-disabled-state-layer-opacity: 0.12;
  --mdc-list-list-item-label-text-color: #1b1b1f;
  --mdc-list-list-item-supporting-text-color: #46464f;
  --mdc-list-list-item-leading-icon-color: #46464f;
  --mdc-list-list-item-trailing-supporting-text-color: #46464f;
  --mdc-list-list-item-trailing-icon-color: #46464f;
  --mdc-list-list-item-selected-trailing-icon-color: #343dff;
  --mdc-list-list-item-disabled-label-text-color: #1b1b1f;
  --mdc-list-list-item-disabled-leading-icon-color: #1b1b1f;
  --mdc-list-list-item-disabled-trailing-icon-color: #1b1b1f;
  --mdc-list-list-item-hover-label-text-color: #1b1b1f;
  --mdc-list-list-item-focus-label-text-color: #1b1b1f;
  --mdc-list-list-item-hover-state-layer-color: #1b1b1f;
  --mdc-list-list-item-hover-state-layer-opacity: 0.08;
  --mdc-list-list-item-focus-state-layer-color: #1b1b1f;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
  --mdc-list-list-item-label-text-font: Roboto, sans-serif;
  --mdc-list-list-item-label-text-line-height: 1.5rem;
  --mdc-list-list-item-label-text-size: 1rem;
  --mdc-list-list-item-label-text-tracking: 0.031rem;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: Roboto, sans-serif;
  --mdc-list-list-item-supporting-text-line-height: 1.25rem;
  --mdc-list-list-item-supporting-text-size: 0.875rem;
  --mdc-list-list-item-supporting-text-tracking: 0.016rem;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: Roboto, sans-serif;
  --mdc-list-list-item-trailing-supporting-text-line-height: 1rem;
  --mdc-list-list-item-trailing-supporting-text-size: 0.688rem;
  --mdc-list-list-item-trailing-supporting-text-tracking: 0.031rem;
  --mdc-list-list-item-trailing-supporting-text-weight: 500;
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 9999px;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-label-text-opacity: 0.3;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
}
html {
  --mat-list-active-indicator-color: #e1e0f9;
  --mat-list-list-item-leading-icon-start-space: 16px;
  --mat-list-list-item-leading-icon-end-space: 16px;
  --mat-list-active-indicator-shape: 9999px;
}
html {
  --mat-paginator-container-text-color: #1b1b1f;
  --mat-paginator-container-background-color: #fcf8fd;
  --mat-paginator-enabled-icon-color: #46464f;
  --mat-paginator-disabled-icon-color: rgba(27, 27, 31, 0.38);
  --mat-paginator-container-text-font: Roboto, sans-serif;
  --mat-paginator-container-text-line-height: 1rem;
  --mat-paginator-container-text-size: 0.75rem;
  --mat-paginator-container-text-tracking: 0.025rem;
  --mat-paginator-container-text-weight: 400;
  --mat-paginator-select-trigger-text-size: 0.75rem;
  --mat-paginator-container-size: 56px;
  --mat-paginator-form-field-container-height: 40px;
  --mat-paginator-form-field-container-vertical-padding: 8px;
  --mat-paginator-touch-target-display: block;
}
html {
  --mdc-secondary-navigation-tab-container-height: 48px;
}
html {
  --mdc-tab-indicator-active-indicator-color: #343dff;
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
}
html {
  --mat-tab-header-divider-color: #e4e1ec;
  --mat-tab-header-pagination-icon-color: #1b1b1f;
  --mat-tab-header-inactive-label-text-color: #1b1b1f;
  --mat-tab-header-active-label-text-color: #1b1b1f;
  --mat-tab-header-active-ripple-color: #1b1b1f;
  --mat-tab-header-inactive-ripple-color: #1b1b1f;
  --mat-tab-header-inactive-focus-label-text-color: #1b1b1f;
  --mat-tab-header-inactive-hover-label-text-color: #1b1b1f;
  --mat-tab-header-active-focus-label-text-color: #1b1b1f;
  --mat-tab-header-active-hover-label-text-color: #1b1b1f;
  --mat-tab-header-active-focus-indicator-color: #343dff;
  --mat-tab-header-active-hover-indicator-color: #343dff;
  --mat-tab-header-label-text-font: Roboto, sans-serif;
  --mat-tab-header-label-text-size: 0.875rem;
  --mat-tab-header-label-text-tracking: 0.006rem;
  --mat-tab-header-label-text-line-height: 1.25rem;
  --mat-tab-header-label-text-weight: 500;
  --mat-tab-header-divider-height: 1px;
}
html {
  --mdc-checkbox-disabled-selected-checkmark-color: #fcf8fd;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.12;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.08;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.12;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.12;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.08;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.12;
  --mdc-checkbox-selected-pressed-icon-color: #343dff;
  --mdc-checkbox-disabled-selected-icon-color: rgba(27, 27, 31, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(27, 27, 31, 0.38);
  --mdc-checkbox-selected-checkmark-color: #ffffff;
  --mdc-checkbox-selected-focus-icon-color: #343dff;
  --mdc-checkbox-selected-hover-icon-color: #343dff;
  --mdc-checkbox-selected-icon-color: #343dff;
  --mdc-checkbox-unselected-focus-icon-color: #1b1b1f;
  --mdc-checkbox-unselected-hover-icon-color: #1b1b1f;
  --mdc-checkbox-unselected-icon-color: #46464f;
  --mdc-checkbox-selected-focus-state-layer-color: #343dff;
  --mdc-checkbox-selected-hover-state-layer-color: #343dff;
  --mdc-checkbox-selected-pressed-state-layer-color: #1b1b1f;
  --mdc-checkbox-unselected-focus-state-layer-color: #1b1b1f;
  --mdc-checkbox-unselected-hover-state-layer-color: #1b1b1f;
  --mdc-checkbox-unselected-pressed-state-layer-color: #343dff;
  --mdc-checkbox-state-layer-size: 40px;
}
html {
  --mat-checkbox-disabled-label-color: rgba(27, 27, 31, 0.38);
  --mat-checkbox-label-text-color: #1b1b1f;
  --mat-checkbox-label-text-font: Roboto, sans-serif;
  --mat-checkbox-label-text-line-height: 1.25rem;
  --mat-checkbox-label-text-size: 0.875rem;
  --mat-checkbox-label-text-tracking: 0.016rem;
  --mat-checkbox-label-text-weight: 400;
  --mat-checkbox-touch-target-display: block;
}
html {
  --mdc-text-button-label-text-color: #343dff;
  --mdc-text-button-disabled-label-text-color: rgba(27, 27, 31, 0.38);
  --mdc-text-button-label-text-font: Roboto, sans-serif;
  --mdc-text-button-label-text-size: 0.875rem;
  --mdc-text-button-label-text-tracking: 0.006rem;
  --mdc-text-button-label-text-weight: 500;
  --mdc-text-button-container-height: 40px;
  --mdc-text-button-container-shape: 9999px;
}
html {
  --mdc-protected-button-container-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-color: #fcf8fd;
  --mdc-protected-button-label-text-color: #343dff;
  --mdc-protected-button-disabled-container-color: rgba(27, 27, 31, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(27, 27, 31, 0.38);
  --mdc-protected-button-label-text-font: Roboto, sans-serif;
  --mdc-protected-button-label-text-size: 0.875rem;
  --mdc-protected-button-label-text-tracking: 0.006rem;
  --mdc-protected-button-label-text-weight: 500;
  --mdc-protected-button-container-height: 40px;
  --mdc-protected-button-container-shape: 9999px;
}
html {
  --mdc-filled-button-container-color: #343dff;
  --mdc-filled-button-label-text-color: #ffffff;
  --mdc-filled-button-disabled-container-color: rgba(27, 27, 31, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(27, 27, 31, 0.38);
  --mdc-filled-button-label-text-font: Roboto, sans-serif;
  --mdc-filled-button-label-text-size: 0.875rem;
  --mdc-filled-button-label-text-tracking: 0.006rem;
  --mdc-filled-button-label-text-weight: 500;
  --mdc-filled-button-container-height: 40px;
  --mdc-filled-button-container-shape: 9999px;
}
html {
  --mdc-outlined-button-disabled-outline-color: rgba(27, 27, 31, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(27, 27, 31, 0.38);
  --mdc-outlined-button-label-text-color: #343dff;
  --mdc-outlined-button-outline-color: #777680;
  --mdc-outlined-button-label-text-font: Roboto, sans-serif;
  --mdc-outlined-button-label-text-size: 0.875rem;
  --mdc-outlined-button-label-text-tracking: 0.006rem;
  --mdc-outlined-button-label-text-weight: 500;
  --mdc-outlined-button-container-height: 40px;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 9999px;
}
html {
  --mat-text-button-state-layer-color: #343dff;
  --mat-text-button-disabled-state-layer-color: #46464f;
  --mat-text-button-ripple-color: rgba(52, 61, 255, 0.12);
  --mat-text-button-hover-state-layer-opacity: 0.08;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mat-text-button-touch-target-display: block;
  --mat-text-button-horizontal-padding: 12px;
  --mat-text-button-with-icon-horizontal-padding: 16px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: -4px;
}
html {
  --mat-protected-button-state-layer-color: #343dff;
  --mat-protected-button-disabled-state-layer-color: #46464f;
  --mat-protected-button-ripple-color: rgba(52, 61, 255, 0.12);
  --mat-protected-button-hover-state-layer-opacity: 0.08;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mat-protected-button-touch-target-display: block;
  --mat-protected-button-horizontal-padding: 24px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -8px;
}
html {
  --mat-filled-button-state-layer-color: #ffffff;
  --mat-filled-button-disabled-state-layer-color: #46464f;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.12);
  --mat-filled-button-hover-state-layer-opacity: 0.08;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mat-filled-button-touch-target-display: block;
  --mat-filled-button-horizontal-padding: 24px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -8px;
}
html {
  --mat-outlined-button-state-layer-color: #343dff;
  --mat-outlined-button-disabled-state-layer-color: #46464f;
  --mat-outlined-button-ripple-color: rgba(52, 61, 255, 0.12);
  --mat-outlined-button-hover-state-layer-opacity: 0.08;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
  --mat-outlined-button-touch-target-display: block;
  --mat-outlined-button-horizontal-padding: 24px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -8px;
}
html {
  --mdc-icon-button-icon-color: #46464f;
  --mdc-icon-button-disabled-icon-color: rgba(27, 27, 31, 0.38);
  --mdc-icon-button-state-layer-size: 40px;
  --mdc-icon-button-icon-size: 24px;
}
html {
  --mat-icon-button-state-layer-color: #46464f;
  --mat-icon-button-disabled-state-layer-color: #46464f;
  --mat-icon-button-ripple-color: rgba(70, 70, 79, 0.12);
  --mat-icon-button-hover-state-layer-opacity: 0.08;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
  --mat-icon-button-touch-target-display: block;
}
html {
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-label-text-font: Roboto, sans-serif;
  --mdc-extended-fab-label-text-size: 0.875rem;
  --mdc-extended-fab-label-text-tracking: 0.006rem;
  --mdc-extended-fab-label-text-weight: 500;
  --mdc-extended-fab-container-height: 56px;
  --mdc-extended-fab-container-shape: 16px;
}
html {
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-color: #e0e0ff;
  --mdc-fab-container-shape: 16px;
}
html {
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-color: #e0e0ff;
  --mdc-fab-small-container-shape: 12px;
}
html {
  --mat-fab-foreground-color: #00006e;
  --mat-fab-state-layer-color: #00006e;
  --mat-fab-ripple-color: rgba(0, 0, 110, 0.12);
  --mat-fab-hover-state-layer-opacity: 0.08;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(27, 27, 31, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(27, 27, 31, 0.38);
  --mat-fab-touch-target-display: block;
}
html {
  --mat-fab-small-foreground-color: #00006e;
  --mat-fab-small-state-layer-color: #00006e;
  --mat-fab-small-ripple-color: rgba(0, 0, 110, 0.12);
  --mat-fab-small-hover-state-layer-opacity: 0.08;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(27, 27, 31, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(27, 27, 31, 0.38);
}
html {
  --mdc-snackbar-container-color: #303034;
  --mdc-snackbar-supporting-text-color: #f3eff4;
  --mdc-snackbar-supporting-text-font: Roboto, sans-serif;
  --mdc-snackbar-supporting-text-line-height: 1.25rem;
  --mdc-snackbar-supporting-text-size: 0.875rem;
  --mdc-snackbar-supporting-text-weight: 400;
  --mdc-snackbar-container-shape: 4px;
}
html {
  --mat-snack-bar-button-color: #bec2ff;
}
html {
  --mat-table-background-color: #fcf8fd;
  --mat-table-header-headline-color: #1b1b1f;
  --mat-table-row-item-label-text-color: #1b1b1f;
  --mat-table-row-item-outline-color: #777680;
  --mat-table-header-headline-font: Roboto, sans-serif;
  --mat-table-header-headline-line-height: 1.25rem;
  --mat-table-header-headline-size: 0.875rem;
  --mat-table-header-headline-weight: 500;
  --mat-table-header-headline-tracking: 0.006rem;
  --mat-table-row-item-label-text-font: Roboto, sans-serif;
  --mat-table-row-item-label-text-line-height: 1.25rem;
  --mat-table-row-item-label-text-size: 0.875rem;
  --mat-table-row-item-label-text-weight: 400;
  --mat-table-row-item-label-text-tracking: 0.016rem;
  --mat-table-footer-supporting-text-font: Roboto, sans-serif;
  --mat-table-footer-supporting-text-line-height: 1.25rem;
  --mat-table-footer-supporting-text-size: 0.875rem;
  --mat-table-footer-supporting-text-weight: 400;
  --mat-table-footer-supporting-text-tracking: 0.016rem;
  --mat-table-header-container-height: 56px;
  --mat-table-footer-container-height: 52px;
  --mat-table-row-item-container-height: 52px;
  --mat-table-row-item-outline-width: 1px;
}
html {
  --mdc-circular-progress-active-indicator-color: #343dff;
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
}
html {
  --mat-badge-background-color: #ba1a1a;
  --mat-badge-text-color: #ffffff;
  --mat-badge-disabled-state-background-color: rgba(186, 26, 26, 0.38);
  --mat-badge-disabled-state-text-color: #ffffff;
  --mat-badge-text-font: Roboto, sans-serif;
  --mat-badge-text-size: 0.688rem;
  --mat-badge-text-weight: 500;
  --mat-badge-large-size-text-size: 0.688rem;
  --mat-badge-container-shape: 9999px;
  --mat-badge-container-size: 16px;
  --mat-badge-small-size-container-size: 6px;
  --mat-badge-large-size-container-size: 16px;
  --mat-badge-legacy-container-size: unset;
  --mat-badge-legacy-small-size-container-size: unset;
  --mat-badge-legacy-large-size-container-size: unset;
  --mat-badge-container-offset: -12px 0;
  --mat-badge-small-size-container-offset: -6px 0;
  --mat-badge-large-size-container-offset: -12px 0;
  --mat-badge-container-overlap-offset: -12px;
  --mat-badge-small-size-container-overlap-offset: -6px;
  --mat-badge-large-size-container-overlap-offset: -12px;
  --mat-badge-container-padding: 0 4px;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0 4px;
  --mat-badge-line-height: 16px;
  --mat-badge-small-size-text-size: 0;
  --mat-badge-small-size-line-height: 6px;
  --mat-badge-large-size-line-height: 16px;
}
html {
  --mat-bottom-sheet-container-text-color: #1b1b1f;
  --mat-bottom-sheet-container-background-color: #f6f2f7;
  --mat-bottom-sheet-container-text-font: Roboto, sans-serif;
  --mat-bottom-sheet-container-text-line-height: 1.5rem;
  --mat-bottom-sheet-container-text-size: 1rem;
  --mat-bottom-sheet-container-text-tracking: 0.031rem;
  --mat-bottom-sheet-container-text-weight: 400;
  --mat-bottom-sheet-container-shape: 28px;
}
html {
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.08;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-standard-button-toggle-text-color: #1b1b1f;
  --mat-standard-button-toggle-state-layer-color: #1b1b1f;
  --mat-standard-button-toggle-selected-state-background-color: #e1e0f9;
  --mat-standard-button-toggle-selected-state-text-color: #191a2c;
  --mat-standard-button-toggle-disabled-state-text-color: rgba(27, 27, 31, 0.38);
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(27, 27, 31, 0.38);
  --mat-standard-button-toggle-disabled-selected-state-background-color: rgba(27, 27, 31, 0.12);
  --mat-standard-button-toggle-divider-color: #777680;
  --mat-standard-button-toggle-label-text-font: Roboto, sans-serif;
  --mat-standard-button-toggle-label-text-line-height: 1.25rem;
  --mat-standard-button-toggle-label-text-size: 0.875rem;
  --mat-standard-button-toggle-label-text-tracking: 0.006rem;
  --mat-standard-button-toggle-label-text-weight: 500;
  --mat-standard-button-toggle-height: 40px;
  --mat-standard-button-toggle-shape: 9999px;
  --mat-standard-button-toggle-background-color: transparent;
  --mat-standard-button-toggle-disabled-state-background-color: transparent;
}
html {
  --mat-datepicker-calendar-date-selected-state-text-color: #ffffff;
  --mat-datepicker-calendar-date-selected-state-background-color: #343dff;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(27, 27, 31, 0.38);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #343dff;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(27, 27, 31, 0.12);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(27, 27, 31, 0.08);
  --mat-datepicker-toggle-active-state-icon-color: #46464f;
  --mat-datepicker-calendar-date-in-range-state-background-color: #e0e0ff;
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: #82ff10;
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #e1e0f9;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #5c5d72;
  --mat-datepicker-toggle-icon-color: #46464f;
  --mat-datepicker-calendar-body-label-text-color: #1b1b1f;
  --mat-datepicker-calendar-period-button-text-color: #46464f;
  --mat-datepicker-calendar-period-button-icon-color: #46464f;
  --mat-datepicker-calendar-navigation-button-icon-color: #46464f;
  --mat-datepicker-calendar-header-text-color: #46464f;
  --mat-datepicker-calendar-date-today-outline-color: #343dff;
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(27, 27, 31, 0.38);
  --mat-datepicker-calendar-date-text-color: #1b1b1f;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(27, 27, 31, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: #343dff;
  --mat-datepicker-range-input-separator-color: #1b1b1f;
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(27, 27, 31, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(27, 27, 31, 0.38);
  --mat-datepicker-calendar-container-background-color: #ebe7eb;
  --mat-datepicker-calendar-container-text-color: #1b1b1f;
  --mat-datepicker-calendar-text-font: Roboto, sans-serif;
  --mat-datepicker-calendar-text-size: 0.875rem;
  --mat-datepicker-calendar-body-label-text-size: 0.875rem;
  --mat-datepicker-calendar-body-label-text-weight: 500;
  --mat-datepicker-calendar-period-button-text-size: 0.875rem;
  --mat-datepicker-calendar-period-button-text-weight: 500;
  --mat-datepicker-calendar-header-text-size: 0.875rem;
  --mat-datepicker-calendar-header-text-weight: 500;
  --mat-datepicker-calendar-container-shape: 16px;
  --mat-datepicker-calendar-container-touch-shape: 28px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-divider-color: transparent;
  --mat-datepicker-calendar-date-outline-color: transparent;
}
html {
  --mat-divider-color: #777680;
  --mat-divider-width: 1px;
}
html {
  --mat-expansion-container-background-color: #fcf8fd;
  --mat-expansion-container-text-color: #1b1b1f;
  --mat-expansion-actions-divider-color: #777680;
  --mat-expansion-header-hover-state-layer-color: rgba(27, 27, 31, 0.08);
  --mat-expansion-header-focus-state-layer-color: rgba(27, 27, 31, 0.12);
  --mat-expansion-header-disabled-state-text-color: rgba(27, 27, 31, 0.38);
  --mat-expansion-header-text-color: #1b1b1f;
  --mat-expansion-header-description-color: #46464f;
  --mat-expansion-header-indicator-color: #46464f;
  --mat-expansion-header-text-font: Roboto, sans-serif;
  --mat-expansion-header-text-size: 1rem;
  --mat-expansion-header-text-weight: 500;
  --mat-expansion-header-text-line-height: 1.5rem;
  --mat-expansion-header-text-tracking: 0.009rem;
  --mat-expansion-container-text-font: Roboto, sans-serif;
  --mat-expansion-container-text-line-height: 1.5rem;
  --mat-expansion-container-text-size: 1rem;
  --mat-expansion-container-text-tracking: 0.031rem;
  --mat-expansion-container-text-weight: 400;
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
  --mat-expansion-container-shape: 12px;
  --mat-expansion-legacy-header-indicator-display: none;
  --mat-expansion-header-indicator-display: inline-block;
}
html {
  --mat-grid-list-tile-header-primary-text-size: 400 1rem / 1.5rem Roboto, sans-serif;
  --mat-grid-list-tile-header-secondary-text-size: 400 0.875rem / 1.25rem Roboto, sans-serif;
  --mat-grid-list-tile-footer-primary-text-size: 400 1rem / 1.5rem Roboto, sans-serif;
  --mat-grid-list-tile-footer-secondary-text-size: 400 0.875rem / 1.25rem Roboto, sans-serif;
}
html {
  --mat-icon-color: inherit;
}
html {
  --mat-sidenav-container-background-color: #fcf8fd;
  --mat-sidenav-container-text-color: #46464f;
  --mat-sidenav-content-background-color: #fcf8fd;
  --mat-sidenav-content-text-color: #1b1b1f;
  --mat-sidenav-scrim-color: rgba(48, 48, 56, 0.4);
  --mat-sidenav-container-shape: 16px;
  --mat-sidenav-container-elevation-shadow: none;
  --mat-sidenav-container-width: 360px;
  --mat-sidenav-container-divider-color: transparent;
}
html {
  --mat-stepper-header-icon-foreground-color: #fcf8fd;
  --mat-stepper-header-selected-state-icon-background-color: #343dff;
  --mat-stepper-header-selected-state-icon-foreground-color: #ffffff;
  --mat-stepper-header-edit-state-icon-background-color: #343dff;
  --mat-stepper-header-edit-state-icon-foreground-color: #ffffff;
  --mat-stepper-container-color: #fcf8fd;
  --mat-stepper-line-color: #777680;
  --mat-stepper-header-hover-state-layer-color: rgba(48, 48, 52, 0.08);
  --mat-stepper-header-focus-state-layer-color: rgba(48, 48, 52, 0.12);
  --mat-stepper-header-label-text-color: #46464f;
  --mat-stepper-header-optional-label-text-color: #46464f;
  --mat-stepper-header-selected-state-label-text-color: #46464f;
  --mat-stepper-header-error-state-label-text-color: #ba1a1a;
  --mat-stepper-header-icon-background-color: #46464f;
  --mat-stepper-header-error-state-icon-foreground-color: #ba1a1a;
  --mat-stepper-container-text-font: Roboto, sans-serif;
  --mat-stepper-header-label-text-font: Roboto, sans-serif;
  --mat-stepper-header-label-text-size: 0.875rem;
  --mat-stepper-header-label-text-weight: 500;
  --mat-stepper-header-error-state-label-text-size: 0.875rem;
  --mat-stepper-header-selected-state-label-text-size: 0.875rem;
  --mat-stepper-header-selected-state-label-text-weight: 500;
  --mat-stepper-header-height: 72px;
  --mat-stepper-header-focus-state-layer-shape: 12px;
  --mat-stepper-header-hover-state-layer-shape: 12px;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
html {
  --mat-sort-arrow-color: #1b1b1f;
}
html {
  --mat-toolbar-container-background-color: #fcf8fd;
  --mat-toolbar-container-text-color: #1b1b1f;
  --mat-toolbar-title-text-font: Roboto;
  --mat-toolbar-title-text-line-height: 1.75rem;
  --mat-toolbar-title-text-size: 1.375rem;
  --mat-toolbar-title-text-tracking: 0;
  --mat-toolbar-title-text-weight: 400;
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
}
html {
  --mat-tree-container-background-color: #fcf8fd;
  --mat-tree-node-text-color: #1b1b1f;
  --mat-tree-node-text-font: Roboto, sans-serif;
  --mat-tree-node-text-size: 1rem;
  --mat-tree-node-text-weight: 400;
  --mat-tree-node-min-height: 48px;
}
html {
  --mat-timepicker-container-background-color: #f0edf1;
  --mat-timepicker-container-shape: 4px;
  --mat-timepicker-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
html {
  --mtx-alert-outline-color: #c7c5d0;
}
html {
  --mtx-alert-background-color: #f0edf1;
}
html {
  --mtx-alert-text-color: #1b1b1f;
}
html {
  --mtx-alert-info-outline-color: #abc7ff;
}
html {
  --mtx-alert-info-background-color: #d7e3ff;
}
html {
  --mtx-alert-info-text-color: #002f65;
}
html {
  --mtx-alert-success-outline-color: #02e600;
}
html {
  --mtx-alert-success-background-color: #cbffb8;
}
html {
  --mtx-alert-success-text-color: #013a00;
}
html {
  --mtx-alert-warning-outline-color: #cdcd00;
}
html {
  --mtx-alert-warning-background-color: #fffeac;
}
html {
  --mtx-alert-warning-text-color: #323200;
}
html {
  --mtx-alert-danger-outline-color: #ffb4a8;
}
html {
  --mtx-alert-danger-background-color: #ffdad4;
}
html {
  --mtx-alert-danger-text-color: #690100;
}
html {
  --mtx-alert-container-shape: 8px;
}
html {
  --mtx-colorpicker-toggle-active-state-icon-color: #46464f;
}
html {
  --mtx-colorpicker-toggle-icon-color: #46464f;
}
html {
  --mtx-datetimepicker-calendar-date-selected-state-text-color: #ffffff;
}
html {
  --mtx-datetimepicker-calendar-date-selected-state-background-color: #343dff;
}
html {
  --mtx-datetimepicker-calendar-date-selected-disabled-state-background-color: rgba(27, 27, 31, 0.38);
}
html {
  --mtx-datetimepicker-calendar-date-today-selected-state-outline-color: #343dff;
}
html {
  --mtx-datetimepicker-calendar-date-focus-state-background-color: rgba(27, 27, 31, 0.12);
}
html {
  --mtx-datetimepicker-calendar-date-hover-state-background-color: rgba(27, 27, 31, 0.08);
}
html {
  --mtx-datetimepicker-clock-hand-background-color: #343dff;
}
html {
  --mtx-datetimepicker-clock-cell-selected-state-background-color: #343dff;
}
html {
  --mtx-datetimepicker-time-input-active-state-text-color: #00006e;
}
html {
  --mtx-datetimepicker-time-input-active-state-background-color: #e0e0ff;
}
html {
  --mtx-datetimepicker-time-input-focus-state-outline-color: #343dff;
}
html {
  --mtx-datetimepicker-time-input-focus-state-placeholder-text-color: #00006e;
}
html {
  --mtx-datetimepicker-time-ampm-selected-state-background-color: #82ff10;
}
html {
  --mtx-datetimepicker-toggle-active-state-icon-color: #46464f;
}
html {
  --mtx-datetimepicker-toggle-icon-color: #46464f;
}
html {
  --mtx-datetimepicker-calendar-body-label-text-color: #1b1b1f;
}
html {
  --mtx-datetimepicker-calendar-header-text-color: #46464f;
}
html {
  --mtx-datetimepicker-calendar-header-divider-color: #c7c5d0;
}
html {
  --mtx-datetimepicker-calendar-table-header-text-color: #1b1b1f;
}
html {
  --mtx-datetimepicker-calendar-date-today-outline-color: #343dff;
}
html {
  --mtx-datetimepicker-calendar-date-text-color: #1b1b1f;
}
html {
  --mtx-datetimepicker-calendar-date-disabled-state-text-color: rgba(27, 27, 31, 0.38);
}
html {
  --mtx-datetimepicker-container-background-color: #ebe7eb;
}
html {
  --mtx-datetimepicker-container-text-color: #1b1b1f;
}
html {
  --mtx-datetimepicker-clock-dial-background-color: #e5e1e6;
}
html {
  --mtx-datetimepicker-clock-cell-text-color: #1b1b1f;
}
html {
  --mtx-datetimepicker-clock-cell-hover-state-background-color: rgba(27, 27, 31, 0.08);
}
html {
  --mtx-datetimepicker-clock-cell-disabled-state-text-color: rgba(27, 27, 31, 0.38);
}
html {
  --mtx-datetimepicker-time-input-text-color: #1b1b1f;
}
html {
  --mtx-datetimepicker-time-input-background-color: #e5e1e6;
}
html {
  --mtx-datetimepicker-time-input-focus-state-background-color: #e0e0ff;
}
html {
  --mtx-datetimepicker-time-input-warn-state-outline-color: #ba1a1a;
}
html {
  --mtx-datetimepicker-time-ampm-text-color: #1b1b1f;
}
html {
  --mtx-datetimepicker-time-ampm-outline-color: #777680;
}
html {
  --mtx-datetimepicker-time-ampm-selected-state-text-color: #0b2000;
}
html {
  --mtx-datetimepicker-calendar-text-font: Roboto, sans-serif;
}
html {
  --mtx-datetimepicker-calendar-text-size: 1rem;
}
html {
  --mtx-datetimepicker-calendar-body-label-text-size: 0.875rem;
}
html {
  --mtx-datetimepicker-calendar-body-label-text-weight: 500;
}
html {
  --mtx-datetimepicker-calendar-period-button-text-size: 0.875rem;
}
html {
  --mtx-datetimepicker-calendar-period-button-text-weight: 500;
}
html {
  --mtx-datetimepicker-clock-text-size: 0.875rem;
}
html {
  --mtx-datetimepicker-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
html {
  --mtx-datetimepicker-container-touch-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
html {
  --mtx-datetimepicker-container-shape: 16px;
}
html {
  --mtx-datetimepicker-container-touch-shape: 28px;
}
html {
  --mtx-datetimepicker-selector-container-shape: 8px;
}
html {
  --mtx-datetimepicker-calendar-header-background-color: transparent;
}
html {
  --mtx-datetimepicker-calendar-date-outline-color: transparent;
}
html {
  --mtx-drawer-container-background-color: #fffbff;
}
html {
  --mtx-drawer-container-text-color: #46464f;
}
html {
  --mtx-drawer-container-shape: 16px;
}
html {
  --mtx-drawer-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
html {
  --mtx-grid-outline-color: #c7c5d0;
}
html {
  --mtx-grid-column-menu-text-color: #46464f;
}
html {
  --mtx-grid-column-menu-divider-color: #c7c5d0;
}
html {
  --mtx-grid-table-footer-background-color: #f0edf1;
}
html {
  --mtx-grid-table-row-striped-background-color: #f0edf1;
}
html {
  --mtx-grid-table-row-hover-background-color: #e1e0f9;
}
html {
  --mtx-grid-table-row-selected-background-color: #e1e0f9;
}
html {
  --mtx-grid-table-row-selected-hover-background-color: #e0e0ff;
}
html {
  --mtx-grid-table-cell-selected-outline-color: #343dff;
}
html {
  --mtx-grid-resizable-handle-active-background-color: #343dff;
}
html {
  --mtx-grid-resizable-handle-hover-background-color: #343dff;
}
html {
  --mtx-grid-resizable-handle-disabled-background-color: #c7c5d0;
}
html {
  --mtx-grid-container-shape: 12px;
}
html {
  --mtx-grid-table-cell-min-width: 80px;
}
html {
  --mtx-loader-backdrop-background-color: rgba(255, 251, 255, 0.75);
}
html {
  --mtx-popover-outline-color: #f0edf1;
}
html {
  --mtx-popover-background-color: #f0edf1;
}
html {
  --mtx-popover-text-color: #1b1b1f;
}
html {
  --mtx-popover-container-shape: 4px;
}
html {
  --mtx-popover-base-elevation-level: 2;
}
html {
  --mtx-progress-track-color: #f0edf1;
}
html {
  --mtx-progress-indicator-color: #c7c5d0;
}
html {
  --mtx-progress-text-color: #1b1b1f;
}
html {
  --mtx-progress-info-indicator-color: #5a64ff;
}
html {
  --mtx-progress-success-indicator-color: #038b00;
}
html {
  --mtx-progress-warning-indicator-color: #bc5d00;
}
html {
  --mtx-progress-danger-indicator-color: #ef0000;
}
html {
  --mtx-progress-text-size: 0.75rem;
}
html {
  --mtx-progress-container-shape: 4px;
}
html {
  --mtx-progress-info-text-color: white;
}
html {
  --mtx-progress-success-text-color: white;
}
html {
  --mtx-progress-warning-text-color: white;
}
html {
  --mtx-progress-danger-text-color: white;
}
html {
  --mtx-select-option-selected-state-text-color: #1b1b1f;
}
html {
  --mtx-select-container-text-color: #1b1b1f;
}
html {
  --mtx-select-placeholder-text-color: rgba(27, 27, 31, 0.38);
}
html {
  --mtx-select-disabled-text-color: rgba(27, 27, 31, 0.38);
}
html {
  --mtx-select-multiple-value-outline-color: #777680;
}
html {
  --mtx-select-multiple-value-icon-hover-background-color: #c7c5d0;
}
html {
  --mtx-select-clear-icon-color: #1b1b1f;
}
html {
  --mtx-select-clear-icon-hover-color: #ba1a1a;
}
html {
  --mtx-select-enabled-arrow-color: #1b1b1f;
}
html {
  --mtx-select-disabled-arrow-color: rgba(27, 27, 31, 0.38);
}
html {
  --mtx-select-invalid-arrow-color: #ba1a1a;
}
html {
  --mtx-select-panel-background-color: #f0edf1;
}
html {
  --mtx-select-panel-divider-color: #777680;
}
html {
  --mtx-select-optgroup-label-text-color: #1b1b1f;
}
html {
  --mtx-select-option-label-text-color: #1b1b1f;
}
html {
  --mtx-select-option-selected-state-background-color: #e1e0f9;
}
html {
  --mtx-select-option-hover-state-background-color: rgba(27, 27, 31, 0.08);
}
html {
  --mtx-select-option-disabled-state-text-color: rgba(27, 27, 31, 0.38);
}
html {
  --mtx-select-container-shape: 4px;
}
html {
  --mtx-select-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
html {
  --mtx-select-multiple-value-background-color: transparent;
}
html {
  --mtx-split-gutter-hover-state-background-color: #343dff;
}
html {
  --mtx-split-gutter-background-color: #c7c5d0;
}
html {
  --mdc-plain-tooltip-container-color: #303034;
}
html {
  --mdc-plain-tooltip-supporting-text-color: #f3eff4;
}
html {
  --mdc-plain-tooltip-supporting-text-line-height: 1rem;
}
html {
  --mdc-plain-tooltip-supporting-text-font: Roboto, sans-serif;
}
html {
  --mdc-plain-tooltip-supporting-text-size: 0.75rem;
}
html {
  --mdc-plain-tooltip-supporting-text-weight: 400;
}
html {
  --mdc-plain-tooltip-supporting-text-tracking: 0.025rem;
}
html {
  --mdc-plain-tooltip-container-shape: 4px;
}

html {
  --mat-expansion-container-background-color: white;
  --mdc-outlined-card-container-color:white;
  --mat-table-background-color:white;
  --mat-paginator-container-background-color:white;
  --mat-sidenav-container-background-color:white;
  --mat-sidenav-content-background-color:white;
  --mat-sidenav-container-shape:0px;
  --mat-paginator-container-text-size:1rem;
  --mat-toolbar-container-background-color:white;
  --mat-divider-color:#e1e0f9;
}
html .mat-grid-tile-content {
  justify-content: start;
}
html .mat-mdc-header-cell {
  @apply capitalize;
}
html .mat-column-action {
  max-width: 8rem;
}

.mat-button-sm {
  --mdc-text-button-container-height: 24px;
}
.mat-button-sm {
  --mdc-filled-button-container-height: 24px;
}
.mat-button-sm {
  --mdc-protected-button-container-height: 24px;
}
.mat-button-sm {
  --mdc-outlined-button-container-height: 24px;
}
.mat-button-sm {
  --mat-text-button-touch-target-display: none;
}
.mat-button-sm {
  --mat-filled-button-touch-target-display: none;
}
.mat-button-sm {
  --mat-protected-button-touch-target-display: none;
}
.mat-button-sm {
  --mat-outlined-button-touch-target-display: none;
}

.mat-icon-button-sm {
  --mat-icon-button-touch-target-display: none;
}
.mat-icon-button-sm .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}

.compact-text-field {
  --mat-form-field-container-height: 36px;
  --mat-form-field-filled-label-display: none;
  --mat-form-field-container-vertical-padding: 6px;
  --mat-form-field-filled-with-label-container-padding-top: 6px;
  --mat-form-field-filled-with-label-container-padding-bottom: 6px;
}
.compact-text-field {
  --mat-select-arrow-transform: none;
}

.medium-text-field {
  --mat-form-field-container-height: 48px;
  --mat-form-field-filled-label-display: none;
  --mat-form-field-container-vertical-padding: 12px;
  --mat-form-field-filled-with-label-container-padding-top: 12px;
  --mat-form-field-filled-with-label-container-padding-bottom: 12px;
}
.medium-text-field {
  --mat-select-arrow-transform: none;
}

.dense-list {
  --mdc-list-list-item-one-line-container-height: 36px;
  --mdc-list-list-item-two-line-container-height: 52px;
  --mdc-list-list-item-three-line-container-height: 76px;
}
.dense-list {
  --mat-list-list-item-leading-icon-start-space: 16px;
  --mat-list-list-item-leading-icon-end-space: 32px;
}
.dense-list .mdc-list-item__start,
.dense-list .mdc-list-item__end {
  --mdc-radio-state-layer-size: 28px;
}
.dense-list .mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .dense-list .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .dense-list .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 44px;
}
.dense-list .mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .dense-list .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .dense-list .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 60px;
}

.mat-chip-sm .mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 24px;
}
.mat-chip-xs .mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 24px;
}