@use '@angular/material' as mat;
html {
  color-scheme: light;
  @include mat.theme(
    (
      color: (
        primary: mat.$blue-palette,
        tertiary: mat.$chartreuse-palette,
        theme-type: light,
      ),
      typography: (
        plain-family: Roboto,
        brand-family: Open Sans,
        bold-weight: 800,
        medium-weight: 500,
        regular-weight: 400,
      ),
      density: 0,
    )
  );
  background-color: white;
  .red-button {
    @include mat.button-overrides(
      (
        filled-container-color: var(--mat-sys-error-container),
        filled-label-text-color: var(--mat-sys-on-error-container),
      )
    );
  }

  .nav-button {
    @include mat.button-overrides(
      (
        filled-container-color: orange,
        filled-label-text-color: red,
      )
    );
  }
}
